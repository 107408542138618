import React, { useState } from "react"
import { Link } from "gatsby"
import { logout } from "../services/auth"
import "../styles.scss";

const Navbar = ({ loggedIn = false }) => {
  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    setIsActive(isActive ? false : true);
  }

  return (
    <div className="container">
    <nav className="navbar py-2 px-4" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link className="navbar-item" to="/app/dashboard">
          <img src="https://avatars.githubusercontent.com/u/75700353?s=200&v=4" width="28" height="28" />
        </Link>
        <a 
          role="button" 
          className={`navbar-burger ${isActive ? "is-active" : null}`} 
          aria-label="menu" 
          aria-expanded="false" 
          data-target="navbarBasicExample"
          onClick={toggleActive}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div id="navbarBasicExample" className={`navbar-menu ${isActive ? "is-active" : null}`}>
        <div className="navbar-start">
          <Link to="/app/dashboard" className="navbar-item">
            Dashboard
          </Link>
          <Link to="/app/apps" className="navbar-item">
            Apps
          </Link>
          <Link to="/app/users" className="navbar-item">
            Users
          </Link>
          <Link to="/app/invites" className="navbar-item">
            Invites
          </Link>
        </div>
      
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              {loggedIn ? (
                <div onClick={logout} className="button is-light">
                  Log out
                </div>
              ) : (
                <Link to="/login" className="button is-light">
                  Log in
                </Link>
              )}
              <Link to="/app/account" className="button is-primary">
                <strong>Account</strong>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
    </div>
  )
}

export default Navbar
