import React, { useState, useEffect } from "react"
import { getApps, getInvites, getUsers } from "../services/queries"
import { buildNewImage, updateAppBackend } from "../services/actions"

const Dashboard = ({ backend }) => {
  const [apps, setApps] = useState([]);
  const [invites, setInvites] = useState([]);
  const [invitePadding, setInvitePadding] = useState(0);
  const [users, setUsers] = useState([]);
  const [version, setVersion] = useState(null);
  const [currentVersion, setCurrentVersion] = useState(backend);
  const [modal, setModal] = useState(false);
  const [appModal, setAppModal] = useState(false);
  const [fnIsRunning, setFnIsRunning] = useState(false);
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  useEffect(() => {
    getApps()
    .then(data => setApps(data))
    .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    getInvites()
    .then(data => setInvites(data))
    .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    getUsers()
    .then(data => setUsers(data))
    .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    if (!invites) return;
    setInvitePadding(`${invites.length}`.length);
  }, [invites]);

  const runVersionUpdate = () => {
    setFnIsRunning(true);
    buildNewImage(version)
      .then((res) => {
        if (res.error) throw res;
        setCurrentVersion({ id: currentVersion, date: { seconds: Date.now() / 1000 } })
      })
      .catch(err => console.log(err))
      .finally(() => {
        setFnIsRunning(false);
        setModal(false);
      });
  }

  const runAppUpdate = () => {
    setFnIsRunning(true);
    updateAppBackend("all", currentVersion)
    .then(res => {
      if (res.error) throw res;
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      setFnIsRunning(false);
      setAppModal(false);
    });
  }

  const updateVersion = (e) => {
    setVersion(e.target.value);
  }

  const lastDay = (obj) => {
    let d = obj.date.toDate();
    return Date.now() - d.getTime() < 24 * 60 * 60 * 100;
  }

  const convertFSDate = (date) => {
    let d = new Date(date.seconds * 1000);
    let mm = d.getMonth();
    let dd = d.getDate();
    let yyyy = d.getFullYear();
    return `${months[mm]} ${dd}`;
  }

  return (
    <div className="container">
      <h1 className="title">Dashboard</h1>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="tile box is-child">
            <h4 className="title is-4 mb-4">Apps</h4>
            <div className="columns mb-2 is-align-items-baseline">
              <div className="column">
                <span className="title is-1 mr-4">{apps.length}</span>
                <span className="subtitle">Total</span>
              </div>
            </div>
            <div className="columns mb-2 is-align-items-baseline">
              <div className="column">
                <span className="title is-1 mr-4">{apps.filter(x => x.plan === "free").length}</span>
                <span className="subtitle">Free</span>
              </div>
            </div>
            <div className="columns mb-2 is-align-items-baseline">
              <div className="column">
                <span className="title is-1 mr-4">{apps.filter(x => x.plan === "launch").length}</span>
                <span className="subtitle">Launch</span>
              </div>
            </div>
          </div>
        </div>  
        <div className="tile is-parent">
          <div className="tile box is-child">
            <h4 className="title is-4 mb-4">Invites</h4>
            <div className="columns mb-2 is-align-items-baseline">
              <div className="column">
                <span className="title is-1 mr-4">{invites.length}</span>
                <span className="subtitle">Total</span>
              </div>
            </div>
            <div className="columns mb-2 is-align-items-baseline">
              <div className="column">
                <span className="title is-1 mr-4">{invites.filter(x => x.active).length}</span>
                <span className="subtitle">Active</span>
              </div>
            </div>
            <div className="columns mb-2 is-align-items-baseline">
              <div className="column">
                <span className="title is-1 mr-4">{invites.filter(x => lastDay(x)).length}</span>
                <span className="subtitle">24 hrs</span>
              </div>
            </div>
          </div>
        </div>  
        <div className="tile is-parent">
          <div className="tile box is-child">
            <h4 className="title is-4 mb-4">Users</h4>
            <div className="columns mb-2 is-align-items-baseline">
              <div className="column">
                <span className="title is-1 mr-4">{users.length}</span>
                <span className="subtitle">Total</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tile is-ancestor">
        <div className="tile is-parent is-4">
          <div className="tile box is-child">
            <h4 className="title is-4 mb-4">Versions</h4>
            <div className="columns mb-2 is-align-items-baseline">
              <div className="column">
                <span className="title is-1 mr-4">{currentVersion.id}</span>
                <span className="subtitle">Current</span>
              </div>
            </div>
            <div className="columns mb-2 is-align-items-baseline">
              <div className="column">
                <span className="title is-1 mr-4">{convertFSDate(currentVersion.date)}</span>
                <span className="subtitle">Published</span>
              </div>
            </div>
            <div className="content mt-4">
              <button className="button is-link" onClick={() => setModal(true)}>Build new image</button>
            </div>
            <div className="content mt-4">
              <button className="button is-link" onClick={() => setAppModal(true)}>Update all apps</button>
            </div>
          </div>
        </div>
      </div>
      <div className={`modal ${modal ? "is-active" : ""}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="box">
            <div className="title is-4">Build new image</div>
            <div className="subtitle">
              What version should this image be? Current version {currentVersion.id}
            </div>
            <div className="columns">
              <div className="column is-half">
            <input type="text" className="input" default="version number" onChange={updateVersion} />
              </div>
            </div>
            <div className="columns">
              <div className="column is-narrow">
                <button 
                  className={`button is-primary ${fnIsRunning ? "is-loading" : ""}`}
                  disabled={!version}
                  onClick={runVersionUpdate}
                >Build image</button>
              </div>
              <div className="column is-narrow">
                <button 
                  disabled={fnIsRunning}
                  className="button is-warning"
                  onClick={() => setModal(false)}
                >Cancel</button>
              </div>
            </div>
          </div>
        </div>
        <button 
          className="modal-close is-large"
          aria-label="close"
          onClick={() => setModal(false)}
        ></button>
      </div>
      <div className={`modal ${appModal ? "is-active" : ""}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="box">
            <div className="title is-4">Update all apps</div>
            <div className="subtitle">
              Are you sure you want to update all apps to version {currentVersion.id}?
            </div>
            <div className="columns">
              <div className="column is-narrow">
                <button 
                  className={`button is-primary ${fnIsRunning ? "is-loading" : ""}`}
                  disabled={fnIsRunning}
                  onClick={runAppUpdate}
                >Update</button>
              </div>
              <div className="column is-narrow">
                <button 
                  disabled={fnIsRunning}
                  className="button is-warning"
                  onClick={() => setAppModal(false)}
                >Cancel</button>
              </div>
            </div>
          </div>
        </div>
        <button 
          className="modal-close is-large"
          aria-label="close"
          onClick={() => setAppModal(false)}
        ></button>
      </div>
    </div>
  )
}

export default Dashboard