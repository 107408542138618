import React, { useState, useEffect } from "react"
import { useParams } from "@reach/router"
import { getApp, getNetlifyData } from "../services/queries"
import { navigate, updateAppBackend } from "../services/actions"
import "../styles.scss"

const Notification = ({ type, message, hide }) => {
  return (
    <div className={`notification is-${type}`}>
      <button className="delete" onClick={hide}></button>
      {message}
    </div>
  )
}

const AppView = ({ backend }) => {
  const [app, setApp] = useState(null);
  const [appData, setAppData] = useState(null);
  const [deployData, setDeployData] = useState(null);
  const [error, setError] = useState(false);
  const [notification, setNotification] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [fnIsRunning, setFnIsRunning] = useState(false);
  const params = useParams();
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  useEffect(() => {
    if (notification) {
      setTimeout(() => setNotification(false), 10000);
    }
  }, [notification]);

  useEffect(() => {
    getApp(params.appId)
      .then(data => setApp(data))
      .catch(err => setError({ fn: "getapp", msg: err }));
  }, [params.appId]);

  useEffect(() => {
    if (!app || !!appData) return;
    setLoading(true);
    getNetlifyData(app.deployId)
    .then(res => {
      const { appData, deployData } = res;
      if (appData.code !== 404) setAppData(appData);
      if (deployData.code !== 404) setDeployData(deployData);
    })
    .catch(err => setError({ fn: "getNetlifyData", msg: err }))
    .finally(() => setLoading(false));
  }, [app, appData]);

  const convertDate = (val) => {
    if (!val) return "";
    let d = new Date(val);
    let M = months[d.getMonth()];
    let dd = `${d.getDate()}`.padStart(2, "0");
    let yyyy = d.getFullYear();
    return `${M} ${dd}, ${yyyy}`;
  }

  const convertDateAndTime = (val) => {
    if (!val) return "";
    let d = new Date(val);
    let M = months[d.getMonth()];
    let dd = `${d.getDate()}`.padStart(2, "0");
    let yyyy = d.getFullYear();
    let hh = d.getHours();
    let mm = d.getMinutes();
    let ampm = hh <= 12 ? "AM" : "PM";
    return `${M} ${dd}, ${yyyy} at ${hh % 12 === 0 ? 12 : hh % 12}:${mm} ${ampm}`;
  }

  const createdStatusTag = (state) => {
    if (state === "ready") {
      return <span className="tag is-success">{state}</span>;
    }
    else if (state === "uploading") {
      return <span className="tag is-info">{state}</span>;
    }
    else if (state === "none") {
      return <span className="tag is-warning">{state}</span>;
    }
    return <span className="tag is-danger">{state}</span>;
  }

  const createdLifecycleTag = (state) => {
    if (state === "active") {
      return <span className="tag is-success">{state}</span>;
    }
    return <span className="tag is-danger">{state}</span>;
  }

  const runUpdate = () => {
    setFnIsRunning(true);
    updateAppBackend(app.id, backend.id)
      .then((res) => {
        if (res.error) throw res;
        setNotification({type: "success", message: "Backend updated successfully" });
        const _app = { ...app };
        _app.backendVersion = backend.id;
        setApp(_app);
      })
      .catch((err) =>  {
        console.log(err);
        setNotification({type: "danger", message: "Couldn't update backend" });
      })
      .finally(() => {
        setFnIsRunning(false);
        setModal(false);
      });
  }

  if (error) {
    return (
      <div className="container">
        <div>An error occurred</div>
      </div>
    )
  }
  if (loading || !appData) {
    return (
      <div className="container">
        {notification && <Notification {...notification} hide={() => setNotification(false)} />}
        <button className="button is-info" onClick={() => navigate(-1)}>
          <span className="icon">
            <i className="fas fa-arrow-left"></i>
          </span>
          <span className="has-text-weight-semibold">Back</span>
        </button>
        <div className="container mt-6">
          <div>
            <div className="columns">
              <div className="column is-three-quarters">
                <div className="title is-size-1 is-spaced">{params && params.appId}</div>
                <div className="subtitle">
                  {loading ? "Loading app data" : "This app has not been deployed"}
                </div>
                {!loading && backend.id !== app.backendVersion ? (
                      <button
                        className="button is-primary"
                        onClick={() => setModal(true)}
                      >Update backend</button>
                    ) : <div className="button is-info is-light">Current</div>}
              </div>
            </div>
          </div>
        </div>    
        <div className={`modal ${modal ? "is-active" : ""}`}>
          <div className="modal-background"></div>
          <div className="modal-content">
            <div className="box">
              <div className="title is-4">Update app backend</div>
              <div className="subtitle">Are you sure you want to update this app?</div>
              <div className="columns">
                <div className="column is-narrow">
                  <button 
                    className={`button is-primary ${fnIsRunning ? "is-loading" : ""}`}
                    onClick={runUpdate}
                  >Yes, update app</button>
                </div>
                <div className="column is-narrow">
                  <button 
                    disabled={fnIsRunning}
                    className="button is-warning"
                    onClick={() => setModal(false)}
                  >Cancel</button>
                </div>
              </div>
            </div>
          </div>
          <button 
            className="modal-close is-large"
            aria-label="close"
            onClick={() => setModal(false)}
          ></button>
        </div> 
      </div>
    )
  }
  return (
    <div className="container">
    {notification && <Notification {...notification} hide={() => setNotification(false)} />}
      <button className="button is-info" onClick={() => navigate(-1)}>
        <span className="icon">
          <i className="fas fa-arrow-left"></i>
        </span>
        <span className="has-text-weight-semibold">Back</span>
      </button>
      <div className="container mt-6">
        <div className="columns">
          <div className="column is-full">
            <div className="columns">
              <div className="column is-one-third">
                <div className="title is-size-1 mb-5">{params && params.appId}</div>
                <div className="subtitle is-6">
                  <div>Created on {convertDate(appData.created_at)}</div>
                  <div>Last deploy {convertDateAndTime(appData.updated_at)}</div>
                </div>
                <div className="field is-grouped is-grouped-multiline">
                  <div className="control">
                    <div className="tags has-addons">
                      <span className="tag is-dark">deploy</span>
                      {appData.published_deploy ? createdStatusTag(appData.published_deploy.state) : createdStatusTag("none")}
                    </div>
                  </div>
                  <div className="control">
                    <div className="tags has-addons">
                      <span className="tag is-dark">status</span>
                      {createdLifecycleTag(appData.lifecycle_state)}
                    </div>
                  </div>
                  <div className="control">
                    <div className="tags has-addons">
                      <span className="tag is-dark">backend</span>
                      {app.backendVersion ? (
                        <span className="tag is-info">v {app.backendVersion}</span>
                      ) : (
                        <span className="tag is-warning">unknown</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-one-quarter">
                <div className="box">
                  <img src={appData.screenshot_url ? appData.screenshot_url : ""} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-6">
          <div className="tile is-ancestor">
            <div className="tile is-parent is-6">
              <div className="tile is-child box">
                <div className="columns is-vcentered">
                  <div className="column">
                    <h2 className="title">Overview</h2>
                  </div>
                  <div className="column is-narrow">
                    {backend.id !== app.backendVersion ? (
                      <button
                        className="button is-primary"
                        onClick={() => setModal(true)}
                      >Update backend</button>
                    ) : <div className="button is-info is-light">Current</div>}
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-narrow">
                    Context
                  </div>
                  <div className="column">
                    <span className="tag mr-2 is-info">
                      {appData.published_deploy && appData.published_deploy.context}
                    </span>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-narrow">Pulic Url</div>
                  <div className="column">
                    {appData.url ? (
                      <a href={appData.url} target="_blank">{appData.url.split("//")[1]}</a>
                      ) : <div>None</div>}
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-narrow">Admin Url</div>
                  <div className="column">
                  {appData.admin_url ? (
                    <a href={appData.admin_url} target="_blank">{appData.admin_url.split("//")[1]}</a>
                    ) : <div>None</div>}
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-narrow">View in editor</div>
                  <div className="column">
                    <a href={`https://app.brevitybuilder.com/${appData.id}/${appData.currentVersion}/canvas/pages/index`} target="_blank">Link</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="tile is-parent is-6">
              <div className="tile is-child box">
                <h2 className="title mb-4">Stats</h2>
                <div className="columns mb-2 is-align-items-baseline">
                  <div className="column">
                    <span className="title is-1 mr-4">{deployData && deployData.length}</span>
                    <span className="subtitle">Deploys</span>
                  </div>
                  <div className="column">
                    <span className="title is-1 mr-4">
                      {deployData && deployData.filter(x => x.state !== "ready").length}
                    </span>
                    <span className="subtitle">Errors</span>
                  </div>
                </div>
                <div className="columns mb-2 is-align-items-baseline">
                  <div className="column">
                    <span className="title is-1 mr-4" style={{ letterSpacing: ".12em" }}>
                      <span style={{ position: "relative", top: "-6px"}}>
                        {deployData && deployData.filter(x => x.state === "ready").length}
                      </span>
                      /
                      <span style={{ position: "relative", top: "6px"}}>
                        {deployData && deployData.length}
                      </span>
                    </span>
                    <span className="subtitle">Successful</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`modal ${modal ? "is-active" : ""}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="box">
            <div className="title is-4">Update app backend</div>
            <div className="subtitle">Are you sure you want to update this app?</div>
            <div className="columns">
              <div className="column is-narrow">
                <button 
                  className={`button is-primary ${fnIsRunning ? "is-loading" : ""}`}
                  onClick={runUpdate}
                >Yes, update app</button>
              </div>
              <div className="column is-narrow">
                <button 
                  disabled={fnIsRunning}
                  className="button is-warning"
                  onClick={() => setModal(false)}
                >Cancel</button>
              </div>
            </div>
          </div>
        </div>
        <button 
          className="modal-close is-large"
          aria-label="close"
          onClick={() => setModal(false)}
        ></button>
      </div>
    </div>
  )
}

export default AppView