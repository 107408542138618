import React, { useState, useEffect } from "react"
import { navigate } from "../services/actions"
import { getUser } from "../services/queries"
import { useParams } from "@reach/router"

const UserView = () => {
  const [user, setUser] = useState(null);
  const params = useParams();

  useEffect(() => {
    if (params) {
      const { userId } = params;
      getUser(userId)
        .then(data => setUser(data));
    }
  }, []);

  return (
    <div className="container">
      <button className="button is-info" onClick={() => navigate(-1)}>
        <span className="icon">
          <i className="fas fa-arrow-left"></i>
        </span>
        <span className="has-text-weight-semibold">Back</span>
      </button>
      <div className="container mt-6">
        <div className="columns">
          {user && (
            <>
              <div className="column is-half"> 
                <div className="box">
                  <div className="title is-5">User Details</div>
                  <div>
                    <div>ID: {user.id}</div>
                    <div>Email: {user.email}</div>
                    <div>Verified: {user.emailVerified ? "Yes" : "No"}</div>
                  </div>
                </div>
              </div>
              <div className="column is-half"> 
                <div className="box">
                  <div className="title is-5">Apps</div>
                  {user.apps.length === 0 && (
                    <div>None</div>
                  )}
                  <div>
                    {user.apps.map(app => {
                      return (
                        <div className="box">
                          <div>App: {app.name}</div>
                          <div>Plan: {app.plan}</div>
                          <div>Role: {app.members[user.id].role}</div>
                          <div>
                            <a href={`https://app.brevitybuilder.com/${app.id}/${app.currentVersion}/canvas/pages/index`}>
                              View in editor
                            </a>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default UserView