import React, { useState, useEffect } from "react"
import { getInvites } from "../services/queries"
import { sendInvite } from "../services/actions"
import { Link } from "gatsby"

const Button = ({ invite, active }) => {
  const [running, setRunning] = useState(false);
  const [isActive, setIsActive] = useState(active);

  const run = () => {
    setRunning(true);
    sendInvite(invite)
    .then(() => {
      setIsActive(false);
      setRunning(false);
    })
    .catch(err => {
      console.log(err);
    })
  }

  if (isActive) return (
    <button 
      className="button"
      style={{ width: "100%" }}
      disabled
    >
      Active
    </button>
  )
  return (
    <button 
      className={`button is-primary ${running ? "is-loading" : null}`}
      onClick={run}
      //onClick={() => alert("disabled for now")}
    >
      Activate
    </button>
  )
}

const Invites = () => {
  const [invites, setInvites] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [search, setSearch] = useState("");
  const headers = ["active", "date", "email", "name"];
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  useEffect(() => {
    getInvites()
    .then(data => setInvites(data))
    .catch(error => console.log(error));
  }, []);

  const toggleFilter = () => {
    setIsFiltered(!isFiltered);
  }

  const convertDate = (obj) => {
    if (!obj) return "";
    let d = obj.toDate();
    let M = months[d.getMonth()];
    let dd = `${d.getDate()}`.padStart(2, "0");
    let yyyy = d.getFullYear();
    return `${M} ${dd}, ${yyyy}`;
  }

  return (
    <div className="container">
      <div className="container mb-4">
        <h1 className="title">Invites</h1>
        <div className="columns">
          <div className="column is-narrow">
            <button className="button is-info" onClick={toggleFilter}>
              Filter Active
            </button>
          </div>
          <div className="column is-half">
            <input 
              className="input" 
              type="text" 
              placeholder="Search by email" 
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Index</th>
            <th></th>
            {invites && headers.map((h, i) => <th key={`col-${i}`}>{h}</th>)}
            <th />
          </tr>
        </thead>
        <tbody>
          {invites && invites.map((invite, index) => {
            return (
              <tr 
                key={`row-${index}`} 
                hidden={(isFiltered && invite.active) || (!invite.email.includes(search))}
              >
                <th>{index + 1}</th>
                <td key={`row-${index}-view`}>
                  <Link className="button is-link" to={`/app/invites/${invite.id}`}>
                    View
                  </Link>
                </td>
                {headers.map((h, i) => {
                  let val = h === "date" ? convertDate(invite[h]) : invite[h];
                  if (h === "active") {
                    val = invite[h] ? "Yes" : "No";
                    return (
                      <td key={`${index}-${i}`}>
                        <div className={`tag is-medium ${invite[h] ? "is-success" : "is-warning"}`}>
                          {val}
                        </div>
                      </td>
                    )
                  }
                  return (
                    <td key={`${index}-${i}`}>{val}</td>
                  )
                })}
                <td>
                  <Button invite={invite.id} active={invite.active} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Invites