import React, { useEffect, useState } from "react"
import { Router, useLocation } from "@reach/router"
import Layout from "../components/layout"
import { auth } from "../../firebase"
import { navigate } from "../services/actions"
import Dashboard from "../components/dashboard"
import Invites from "../components/invites"
import InviteView from "../components/inviteView"
import Apps from "../components/apps"
import AppView from "../components/appView"
import Users from "../components/users"
import UserView from "../components/userView"
import Account from "../components/account"
import { getVersions } from "../services/queries"

const App = () => {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [versions, setVersions] = useState(null);
  const [currentVersion, setCurrentVersion] = useState(null);

  useEffect(() => {
    const unsub = auth.onAuthStateChanged((u) => {
      if (u) {
        setUser(u.toJSON());
        setIsLoggedIn(true);
      } else {
        setUser(null);
        setIsLoggedIn(false);
        navigate("/login");
      }
    });
    return unsub;
  }, [location]);

  useEffect(() => {
    getVersions()
    .then(data => {
      setVersions(data);
      if (data && data[0]) {
        setCurrentVersion(data[0]);
      }
    })
    .catch(error => console.log(error));
  }, []);

  if (!isLoggedIn || !currentVersion  ) {
    return <div />
  }
  return (
    <Layout loggedIn={isLoggedIn} >
      <Router>
        <Dashboard path="/app/dashboard" backend={currentVersion} />
        <Account path="/app/account" user={user} />
        <Apps path="/app/apps" backend={currentVersion} versions={versions} />
        <AppView path="/app/apps/:appId" backend={currentVersion} />
        <Invites path="/app/invites" />
        <InviteView path="/app/invites/:inviteId" />
        <Users path="/app/users" />
        <UserView path="/app/users/:userId" />
      </Router>
    </Layout>
  )
}
export default App