import { navigate as nav } from "gatsby"

export const sendInvite = async (inviteId) => {
  const baseUrl = "https://us-central1-deft-cef16.cloudfunctions.net/activateInvite";
  const url = `${baseUrl}?invite=${inviteId}`
  return fetch(url, { mode: "cors" })
  .then(() => ({ message: "done" }))
  .catch(err => ({error: true, details: { ...err }}));
}

export const buildNewImage = async (version) => {
  const url = "https://us-central1-deft-cef16.cloudfunctions.net/newBackendVersion";
  return fetch(`${url}?version=${version}`, { 
    method: "get", 
    mode: "no-cors", //must be no-cors for body to be sent 
    })
    .then(() => ({ message: "done" }))
    .catch(err => ({error: true, details: { ...err }}));
}

export const updateAppBackend = async (appId, version) => {
  const url = "https://us-central1-deft-cef16.cloudfunctions.net/updateBackend";
  return fetch(`${url}?appId=${appId}&version=${version}`, { 
    method: "get", 
    mode: "no-cors"
  })
  .then((res) => { console.log(res); return ({ message: "done" })})
  .catch(err => ({error: true, details: { ...err }}));
}

export const navigate = (path) => {
  if (typeof window === "undefined" || !window) return;
  nav(path);
}