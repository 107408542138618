import * as React from "react"
import Navbar from "./navbar"
import Footer from "./footer"
import { Helmet } from "react-helmet"

const Layout = ({ children, loggedIn }) => {
  return (
    <main>
    <Helmet>
      <title>Brevity Admin Panel</title>
      <link rel="icon" type="image" href="https://firebasestorage.googleapis.com/v0/b/deft-cef16.appspot.com/o/favicon.png?alt=media&token=7fe9e52c-3da9-4895-a14f-40ee2f7e00cb" />
    </Helmet>
      <Navbar loggedIn={loggedIn} />
      <div className="container px-4">
        {children}
      </div>
      <Footer />
    </main>
  )
}

export default Layout