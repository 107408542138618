import React, { useState, useEffect } from "react"
import { getApps } from "../services/queries"
import { Link } from "gatsby"

const Apps = ({ backend, versions }) => {
  const [apps, setApps] = useState(null);
  const [filterFree, setFilterFree] = useState(false);
  const [versionFilter, setVersionFilter] = useState("*");
  const headers = ["id", "backendVersion", "domain", "members", "plan", "subscriptionId", "deployId"]

  useEffect(async () => {
    getApps()
    .then(data => setApps(data))
    .catch(error => console.log(error));
  }, []);

  const toggleFilter = () => setFilterFree(!filterFree);

  const runFilter = (value) => {
    if (filterFree && value.plan === "free") return true;
    if (versionFilter !== "*" && versionFilter !== value.backendVersion) return true;
    return false;
  }

  return (
    <div className="container">
      <div className="container mb-4">
        <h1 className="title">Apps</h1>
        <div className="columns">
          <div className="column is-narrow">
            <button className="button is-info" onClick={toggleFilter}>
              {filterFree ? "Reset" : "Filter Free"}
            </button>
          </div>
          <div className="column is-narrow">
            <div className="field has-addons has-addons-centered">
              <div className="control px-4 has-background-info-light"
                style={{
                  borderRadius: '4px 0 0 4px',
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <p className="has-text-info-dark">Backend Version </p>
              </div>
              <div className="control">
                <div className="select">
                  <select default="*" onChange={(e) => setVersionFilter(e.target.value)}>
                    <option value="*">Any</option>
                    {versions.map(v => <option key={v.id} value={v.id}>{v.id}</option>)}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Index</th>
            <th></th>
            {apps && headers.map((h, i) => <th key={`col-${i}`}>{h}</th>)}
          </tr>
        </thead>
        <tbody>
          {apps && apps.map((app, index) => {
            return (
              <tr key={app.id} hidden={runFilter(app)}>
                <th key={`row-${index}`}>{index + 1}</th>
                <td key={`row-${index}-view`}>
                  <Link className="button is-link" to={`/app/apps/${app.id}`}>
                    View
                  </Link>
                </td>
                {headers.map((h, i) => {
                  let val = h === "members" ? Object.keys(app[h]).length : app[h];
                  return (
                    <td key={`${index}-${i}`}>{val}</td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Apps