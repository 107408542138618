import React, { useState, useEffect } from "react"
import { getUsers } from "../services/queries"
import { Link } from "gatsby"

const Users = () => {
  const [users, setUsers] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [search, setSearch] = useState("");
  const headers = ["id", "email"];
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  useEffect(() => {
    getUsers()
    .then(data => setUsers(data))
    .catch(error => console.log(error));
  }, []);

  const toggleFilter = () => {
    setIsFiltered(!isFiltered);
  }

  const convertDate = (obj) => {
    if (!obj) return "";
    let d = obj.toDate();
    let M = months[d.getMonth()];
    let dd = `${d.getDate()}`.padStart(2, "0");
    let yyyy = d.getFullYear();
    return `${M} ${dd}, ${yyyy}`;
  }

  return (
    <div className="container">
      <div className="container mb-4">
        <h1 className="title">Users</h1>
        <div className="columns">
          <div className="column is-narrow">
            <button className="button is-info" onClick={toggleFilter}>
              Filter Active
            </button>
          </div>
          <div className="column is-half">
            <input 
              className="input" 
              type="text" 
              placeholder="Search by email" 
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Index</th>
            <th></th>
            {users && headers.map((h, i) => <th key={`col-${i}`}>{h}</th>)}
            <th />
          </tr>
        </thead>
        <tbody>
          {users && users.map((user, index) => {
            return (
              <tr 
                key={`row-${index}`} 
                hidden={(isFiltered && user.active) || (!user.email.includes(search))}
              >
                <th>{index + 1}</th>
                <td key={`row-${index}-view`}>
                  <Link className="button is-link" to={`/app/users/${user.id}`}>
                    View
                  </Link>
                </td>
                {headers.map((h, i) => {
                  let val = h === "date" ? convertDate(user[h]) : user[h];
                  if (h === "emailVerified") {
                    val = user[h] ? "Yes" : "No";
                    return (
                      <td key={`${index}-${i}`}>
                        <div className={`tag is-medium ${user[h] ? "is-success" : "is-warning"}`}>
                          {val}
                        </div>
                      </td>
                    )
                  }
                  return (
                    <td key={`${index}-${i}`}>{val}</td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Users