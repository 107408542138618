import { db } from "../../firebase"

export const getApps = () => {
  return db
    .collection("apps")
    .get()
    .then(snap => {
      let appData = [];
      snap.docs.forEach(doc => appData.push({ ...doc.data() }));
      return appData;
    });
}

export const getApp = (appId) => {
  return db
    .collection("apps")
    .doc(appId)
    .get()
    .then(doc => {
      return { id: doc.id, ...doc.data() };
    });
}

export const getInvites = () => {
  return db
    .collection("invites")
    .orderBy("date", "desc")
    .get()
    .then(snap => {
      let invites = [];
      snap.docs.forEach(doc => invites.push({ id: doc.id, ...doc.data() }));
      return invites;
    });
}

export const getInvite = (id) => {
  return db
    .collection("invites")
    .doc(id)
    .get()
    .then(doc => ({ id: doc.id, ...doc.data() }));
}

export const getUsers = () => {
  return db
    .collection("users")
    .get()
    .then(snap => {
      let users = [];
      snap.docs.forEach(doc => users.push({ id: doc.id, ...doc.data() }));
      return users;
    })
}

export const getUser = (id) => {
  const userPromise = db
    .collection("users")
    .doc(id)
    .get()
    .then(doc => ({ id: doc.id, ...doc.data() }));
  const appsPromise = db
    .collection("apps")
    .get()
    .then(snap => {
      let userApps = [];
      snap.docs.forEach(doc => {
        let app = doc.data();
        if (Object.keys(app.members).includes(id)) {
          userApps.push(app);
        }
      });
      return userApps;
    });
  
  return Promise.all([userPromise, appsPromise])
    .then(data => {
      let user = data[0];
      let apps = data[1];
      try {
        user.apps = apps;
        console.log(user);
        return user;
      }
      catch (err) {
        return user;
      }
    })
}

export const getVersions = () => {
  return db
    .collection("versions")
    .orderBy("date", "desc")
    .get()
    .then(snap => {
      let versions = [];
      snap.docs.forEach(doc => versions.push({ ...doc.data() }));
      return versions;
    })
}

export const getNetlifyData = async (deployId) => {
  return fetch(`/.netlify/functions/getappdata?app=${encodeURI(deployId)}`)
    .then(res => res.json())
}