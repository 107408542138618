import * as React from "react"
import "../styles.scss"

const Footer = () => (
  <footer className="footer" style={{ marginTop: "6em" }}>
    <div className="container mt-6 px-5">
      <div className="content">
        <h1 className="title">Brevity Builder</h1>
        <div className="subtitle is-6 is-align-items-center">
          <span className="icon mr-1" style={{ fontSize: ".85em" }}>
            <i className="far fa-copyright"></i>
          </span>
          <span>Brevity Builder 2021</span>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer