import React, { useState, useEffect, useRef } from "react"
import { navigate } from "../services/actions"
import { useParams } from "@reach/router"
import { getInvite } from "../services/queries"

const InviteView = () => {
  const [answers, setAnswers] = useState(null);
  const [copied, setCopied] = useState(false);
  const params = useParams();
  const input = useRef();

  useEffect(() => {
    if (params) {
      const { inviteId } = params;
      getInvite(inviteId)
        .then(data => setAnswers(data.answers));
    }
  }, [params]);

  const copy = () => {
    let val = `https://app.brevitybuilder.com/auth/signup?invite=${params.inviteId}`;
    navigator.clipboard.writeText(val);
    setCopied(true);
  }

  return (
    <div className="container">
      <button className="button is-info" onClick={() => navigate(-1)}>
        <span className="icon">
          <i className="fas fa-arrow-left"></i>
        </span>
        <span className="has-text-weight-semibold">Back</span>
      </button>
      <div className="container mt-6">
        <div className="columns">
          <div className="column is-half is-offset-one-quarter">
            <div className="box">
              <div className="columns is-multiline is-vcentered">
                <div className="column pb-0">
                  <div className="title is-5">Invite Link</div>
                </div>
                <div className="column is-narrow pb-0">
                  <button 
                    className="button is-small is-light"
                    onClick={copy}
                  >{copied ? "Copied" : "Copy link"}</button>
                </div>
                <div className="column is-full">
                  <div 
                    className="subtitle is-6 has-text-link"
                    onClick={() => {
                      input.current.select();
                      document.execCommand("copy");
                    }}
                  >{`https://app.brevitybuilder.com/auth/signup?invite=${params.inviteId}`}</div>
                </div>
              </div>
            </div>
            {answers && answers.map((a, i) => {
              switch (i) {
                case 0:
                  return (
                    <div key={i} className="container mb-4">
                      <div className="title is-4">Name</div>
                      <div className="subtitle">{a.text}</div>
                    </div>
                  )
                case 1:
                  return (
                    <div key={i} className="container mb-4">
                      <div className="title is-4">Email</div>
                      <div className="subtitle">{a.email}</div>
                    </div>
                  )
                case 2:
                  return (
                    <div key={i} className="container mb-4">
                      <div className="title is-4">Twitter</div>
                      <div className="subtitle">
                        {a.text && a.text.length > 0 ? (
                          <a 
                            href={
                              `https://twitter.com/${a.text[0] === "@" 
                              ? a.text.slice(1) 
                              : a.text}`
                            }
                            target="_blank"
                          >{a.text}</a>
                        ) : "No twitter handle given"}
                      </div>
                    </div>
                  )
                case 3:
                  return (
                    <div key={i} className="container mb-4">
                      <div className="title is-4">Which of the following tools have you used?</div>
                      <div className="subtitle">
                        {a.choices ? a.choices.labels.map((l, i) => (
                          <div className="mb-2">
                            <span>{i + 1}.</span>
                            {" "}{l}
                          </div>
                        )) : "None"}
                      </div>
                    </div>
                  )
                case 4:
                  return (
                    <div key={i} className="container mb-4">
                      <div className="title is-4">How did you hear about Brevity?</div>
                      <div className="subtitle">
                        {a.choice ? Object.keys(a.choice).map(k => <div>{a.choice[k]}</div>) : "No answer"}
                      </div>
                    </div>
                  )
                case 5:
                  return (
                    <div key={i} className="container mb-4">
                      <div className="title is-4">What are you hoping to build on Brevity?</div>
                      <div className="subtitle">{a.text}</div>
                    </div>
                  )
              }
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default InviteView