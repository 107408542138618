import React from "react"

const Account = ({ user }) => {

  const createTag = () => {
    if (user.emailVerified) {
      return <span className="tag is-success">Yes</span>;
    }
    return <span className="tag is-danger">No</span>;
  }

  if (!user) return null;
  return (
    <div className="container">
      <h1 className="title is-spaced">Account</h1>
      <div className="container">
        <div>
          <h2 className="title">{user.displayName}</h2>
          <span className="subtitle">{user.email}</span>
          <div className="control mt-2">
            <div className="tags has-addons">
              <span className="tag is-dark">verified</span>
              {createTag()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Account